import axios, { AxiosResponse } from "axios";
import { API_URL, getHeaderWithAuthorization } from "./API";
import { TThunkDispatch } from "store";
import type { LectureVideo } from "pages/LectureArchive/redux";
import {
  setBadRequestLectureArchive,
  setInteractiveTaskStats,
  setLectureArchive,
  setLectureArchiveInfo,
} from "pages/LectureArchive/redux";

import { setNotification } from "layout";

export const getLectureArchive = () => {
  return (dispatch: TThunkDispatch) => {
    axios
      .get(API_URL + "v1/webinar/archive", {
        headers: getHeaderWithAuthorization(),
      })
      .then((res: AxiosResponse<LectureVideo[]>) => {
        if (res.statusText === "OK") {
          dispatch(setLectureArchive(res.data));
        } else {
          dispatch(setBadRequestLectureArchive());
        }
      })
      .catch(() => {
        dispatch(setBadRequestLectureArchive());
      });
  };
};

export const getLectureArchiveInfo = (id: number) => {
  return (dispatch: TThunkDispatch) => {
    Promise.all([
      axios.get(API_URL + `v1/webinar/tasks/${id}`, {
        headers: getHeaderWithAuthorization(),
      }),
      axios.get(API_URL + `v1/webinar/chat/${id}`, {
        headers: getHeaderWithAuthorization(),
      }),
      axios.get(API_URL + `v1/webinar/timings/${id}`, {
        headers: getHeaderWithAuthorization(),
      }),
    ])
      .then(
        ([tasks, chat, timings]: [
          AxiosResponse<{ tasks: any[] }>,
          AxiosResponse<{ chat: any[] }>,
          AxiosResponse<{ timings: any[] }>
        ]) => {
          dispatch(
            setLectureArchiveInfo({
              id,
              tasks: tasks.data.tasks,
              chat: chat.data.chat,
              timings: timings.data.timings,
            })
          );
        }
      )
      .catch((err) => {
        dispatch(setBadRequestLectureArchive());
      });
  };
};

export const getInteractiveTaskStats = () => {
  return (dispatch: TThunkDispatch) => {
    axios
      .get(API_URL + "v1/stat/interactive-task", {
        headers: getHeaderWithAuthorization(),
      })
      .then((res) => {
        dispatch(setInteractiveTaskStats(res.data));
      })
      .catch((err) => {
        dispatch(
          setNotification({
            title: "Ошибка",
            message: `error code: ${err.code}, message: ${err.message}`,
            status: "error",
          })
        );
      });
  };
};

export const sendViewLectureWatched = async (lesson_id: number) => {
  try {
    return await axios.post(
      API_URL + `v1/cabinet/actions/set-archived-watched`,
      { lesson_id },
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    console.log({ err });
  }
};

export const getStatistics = async (id: number) => {
  try {
    return await axios.get(API_URL + `v1/cabinet/student/full-stat/${id}`, {
      headers: getHeaderWithAuthorization(),
    });
  } catch (err: any) {
    console.log({ err });
  }
};

export const sendUpdateTimeWatch = async (lesson_id: number, time: number) => {
  try {
    return await axios.post(
      `${API_URL}v1/cabinet/actions/set-archive-watch-time`,
      { lesson_id, time },
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    localStorage.setItem(`timeViewLast_${lesson_id}`, JSON.stringify({ time }));
    console.log({ err });
  }
};
