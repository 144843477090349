import videoPreview from "assets/images/loginVideoPreview.png";
import playButton from "assets/images/play.png";
import humanImage from "assets/images/loginHuman.png";
import Heroblocks from "assets/images/Heroblocks.svg";

import { LoginForm } from "../index";

type Props = {
  name: string;
  video: string;
  handleForgot: () => void;
  handlePlayButtonClick: (videoUrl: string) => void;
};

const HeroBlock = ({
  name,
  video,
  handleForgot,
  handlePlayButtonClick,
}: Props) => {
  return (
    <div className="WrapperHeadBlock" style={{backgroundImage: `url(${Heroblocks})`}}>
      <div className="Container">
        <div className="LoginContainerNew">
          {/*<div className="LoginAuth">*/}
          {/*  <div className="LoginTitle">Дистанционное обучение автошколы ”{name}”</div>*/}
            <LoginForm handleForgot={handleForgot} />
          {/*</div>*/}
          {/*<div className="LoginPreview">*/}
          {/*  <div className="LoginVideoPreview" style={{backgroundImage: `url(${videoPreview})`}}>*/}
          {/*    <img*/}
          {/*        className="PlayVideoButton"*/}
          {/*        src={playButton}*/}
          {/*        alt="alt"*/}
          {/*        onClick={() => handlePlayButtonClick(video)}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <img className="HumanImage" src={humanImage} alt="alt"/>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};
export default HeroBlock;
