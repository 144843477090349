import { AboutBlock, Header, HeroBlock, Footer } from "./components";
import { openModal } from "../../layout";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { getSchoolInfo } from "../../api/UserAPI";
import { Loader } from "../../components";
import styled from "styled-components";
import './styles.css';

const video1 = "https://288525.selcdn.ru/pdd_tv_video/example_online.mp4";
const video2 = "https://288525.selcdn.ru/pdd_tv_video/multrolik_wl.mp4";

export interface ISchool {
  use_base_course: boolean;
  use_billing: boolean;
  contact_person: string;
  contact_phone: string;
  logo: string;
  name: string;
  style: string
}

export const subdomain = window.location.hostname.split(".")[0];
export const validSubdomain =
  subdomain === "localhost" || subdomain === "frontend"
    ? "svetofor"
    : subdomain;

const LoginDefault = React.memo(() => {
  const dispatch = useDispatch();
  const [school, setSchool] = useState<ISchool | null>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await getSchoolInfo(validSubdomain);

        setSchool(resp.data);
      } catch (e) {
        console.log(e);
      }
    };

    getData();
  }, []);

  const handleForgot = () => {
    dispatch(openModal({ name: "PasswordForgotModal" }));
  };

  const handlePlayButtonClick = (video: string) => {
    dispatch(openModal({ name: "VideoBoxModal", video }));
  };

  if (school === null) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <div style={{ flex: 1 }}>
      {/*<link rel="stylesheet" href={school.style}/>*/}
      {/*<Header logo={school.logo} phone={school.contact_phone} />*/}
      <HeroBlock
        name={school.name}
        video={video1}
        handleForgot={handleForgot}
        handlePlayButtonClick={handlePlayButtonClick}
      />
      {/*<AboutBlock*/}
      {/*    name={school.name}*/}
      {/*    video={video2}*/}
      {/*  handlePlayButtonClick={handlePlayButtonClick}*/}
      {/*/>*/}
      {/*<Footer />*/}
    </div>
  );
});

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default LoginDefault;
