import { useSelector } from "react-redux";
import {
  ConfirmStartWebinarModal,
  MicrophoneSettingsModal,
  WebinarTaskList,
  WebinarTask,
  ActivateMicrophoneErrorModal,
  PaymentsModal,
  PaymentsErrorModal,
  PaymentsSuccessModal,
  LectureRatingBeforeFinish,
  WebinarTaskModal,
  ADMINWebinarTaskChoiceModal,
  AskModal,
  ArchiveTaskModal,
  PasswordForgotModal,
  VideoBoxModal,
  UserMuteConfirmModal,
  ArchivePlaybackModal,
  ArchiveNotWatchedVideo,
  PaymentListModal,
} from "./modals";
import { getModals } from "./redux/selectors";
import { ModalWindowContainer } from "./styledComponents";
import { getModalRestrictions } from "../../selectors/modalRestrictions";

const ModalCollection: { [key: string]: any } = {
  confirmStartWebinarModal: ConfirmStartWebinarModal,
  MicrophoneSettingsModal,
  WebinarTaskList,
  WebinarTask,
  ActivateMicrophoneErrorModal,
  PaymentsModal,
  PaymentsErrorModal,
  PaymentsSuccessModal,
  LectureRatingBeforeFinish,
  ArchiveTaskModal,
  WebinarTaskModal,
  ADMINWebinarTaskChoiceModal,
  AskModal,
  PasswordForgotModal,
  VideoBoxModal,
  UserMuteConfirmModal,
  ArchivePlaybackModal,
  ArchiveNotWatchedVideo,
  PaymentListModal,
};

const ModalWindow = () => {
  const modals = useSelector(getModals);
  const modalsRestrictions = useSelector(getModalRestrictions);
  const modal = modals[0] || null;

  if (!modals.length) {
    //@ts-ignore
    document.body.style = "overflow: auto";
    return null;
  }

  window.scrollTo(0, 0);
  //@ts-ignore
  document.body.style = "overflow: hidden";
  const CurrentModal = ModalCollection[modal.name];

  if (!modal) {
    return null;
  }

  if (modalsRestrictions.restricted.includes(modal.name)) {
    return null;
  }

  return (
    <>
      <ModalWindowContainer key={modal.name}>
        <CurrentModal {...modal} />
      </ModalWindowContainer>
    </>
  );
};

export default ModalWindow;
