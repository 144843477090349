import { setCabinetLecturePromoLoad } from "pages/Cabinet/redux/reducer";
import { TThunkDispatch } from "store";
import centrifuge from "./Centrifuge";
import axios from "axios";
import { API_URL, getHeaderWithAuthorization } from "./API";

export const postStartWebinarActionCreator =
  (id: number) => (dispatch: TThunkDispatch) => {
    postWebinarStatus("webinar/start", "webinar" + id).then(
      function (res) {
        if (res.data.success) {
          dispatch(setCabinetLecturePromoLoad(true));
        }
      },
      function (err) {
        console.log(err, "RPC ERROR:", "postStartWebinarActionCreator");
      }
    );
  };

const postWebinarStatus = (method: string, webinarChannel: string) => {
  return centrifuge.rpc({ method: method, data: { channel: webinarChannel } });
};

export const subscribeToWebinarChannel = (webinarChannel: string) => () => {
  centrifuge.subscribe(webinarChannel, function (res) {
    console.log("subscribeToWebinarChannel");
  });
};

export const finishWebinarActionCreator = (id: number) => () => {
  postWebinarStatus("webinar/finish", "webinar" + id).then(() => {});
};

export const getWebinarsList = () => () => {
  centrifuge
    .rpc({ method: "webinar/list", data: {} })
    .then(console.log)
    .catch(console.log);
};

export const getListReasonsByStopWebinar = async () => {
  try {
    const { data } = await axios.get(`${API_URL}v1/pause/list`, {
      headers: getHeaderWithAuthorization(),
    });
    return data;
  } catch (err: any) {
    return [];
  }
};

export const setReasonWebinar = async (lessonId: number, pauseId: number) => {
  try {
    const { data } = await axios.post(
      `${API_URL}v1/pause/set?lesson_id=${lessonId}&pause_id=${pauseId}`,
      {
        headers: getHeaderWithAuthorization(),
      }
    );
    return data;
  } catch (err: any) {
    return false;
  }
};

export const unsetReasonWebinar = async (lessonId: number) => {
  try {
    const { data } = await axios.post(
      `${API_URL}v1/pause/unset?lesson_id=${lessonId}`,
      {
        headers: getHeaderWithAuthorization(),
      }
    );
    return data;
  } catch (err: any) {
    return false;
  }
};
