import { useState, useEffect } from "react";
import { Button } from "components";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { getExam } from "./redux";
import { useHistory } from "react-router";
import { EXAM } from "constants/paths";
import { getExamStart } from "../../api/PddTicketAPI";

const StarterWrapper = styled.div`
  margin: 243px auto;

  @media screen and (max-width: 910px) {
    margin: 15px auto;
  }
`;

const StarterTitle = styled.p`
  text-align: center;
  font-size: 1.875em;
  font-weight: 600;
  margin: 0 0 24px;
`;
const StarterDescription = styled.p`
  text-align: center;
  max-width: 510px;
  margin: auto auto 40px;
`;
const StartButton = styled(Button)`
  display: block;
  margin: auto;
  width: 137px;
  height: 40px;
`;

const StartExam = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const exam = useSelector(getExam);
  const dispatch = useDispatch();

  const handleStartExam = useCallback(() => {
    setLoading(true);
    dispatch(getExamStart());
  }, [dispatch]);

  useEffect(() => {
    if (exam) {
      setLoading(false);
      history.push(`${EXAM}?type=exam`);
    }
  }, [exam]);

  return (
    <StarterWrapper>
      <StarterTitle>Экзамен по ПДД 2023</StarterTitle>
      <StarterDescription>
        Экзаменационный билет состоит из 20 вопросов. У Вас будет всего 20 минут
        что бы ответить на все вопросы. Максимальное количество ошибок - 2, за
        каждую добавляются по 5 вопросов, ответив на которые, ошибки считаются
        исправленными.
      </StarterDescription>
      <StartButton disabled={loading} onClick={handleStartExam}>
        Начать
      </StartButton>
    </StarterWrapper>
  );
};

export default StartExam;
