import styled, { createGlobalStyle } from "styled-components";

import {
  AppBar,
  // AppWidget,
  Header,
  ModalWindow,
  OnlineWebinarLectureWidget,
  ContentContainer,
} from "layout";
import { Webinars, ListenerOnlineWebinar, Login } from "pages";
import { Preloader } from "components";

import { Route, Switch, useHistory } from "react-router";
import React, { Suspense, useCallback, useEffect } from "react";
import checkDeviceSupport from "helpers/checkDeviceSupport";
import { useDispatch, useSelector } from "react-redux";
import { getLoader, setClientWidth, setLoader, setUserDevices } from "reducers";
import { Redirect } from "react-router-dom";
import {
  DOCUMENTS_PATH,
  LECTURE_ARCHIVE_PATH,
  LOGIN_PATH,
  ONLINE_LECTURE_PATH,
  SERVICES_AND_PAYMENTS_PATH,
  WEBINARS_PATH,
  LECTURE_VIDEO_PATH,
  TESTS,
  WORKOUT,
  START_EXAM,
  TICKETS_THEMES,
  EXAM,
  BASE_COURSES,
  BASE_COURSES_VIEW,
  BASE_COURSES_LESSON,
} from "constants/paths";
import { getUserInfoActionCreator } from "api/UserAPI";
import { push } from "connected-react-router";
import { RedirectForManager } from "containers";
import RedirectForUser from "containers/RedirectForUser";
import {
  getToken,
  initCanvasDrawSettings,
  useCentrifugeConnected,
} from "utils";
import { getUserName, getUserRole } from "selectors/userInfo";
import moment from "moment";
import { Notifications } from "layout";
import { BaseCourses } from "./pages/BaseCourses";
import { BaseCourseView } from "./pages/BaseCourses/BaseCoursesView";
import { BaseCourseVideo } from "./pages/BaseCourses/BaseCourseVideo";
import PaymentsPage from "./pages/Payments";

const Cabinet = React.lazy(() => import("./pages/Cabinet/Cabinet"));
const Documents = React.lazy(() => import("./pages/Documents"));
const InWork = React.lazy(() => import("./pages/InWork"));
const Payments = React.lazy(() => import("./pages/Payments"));
const LectureArchive = React.lazy(() => import("./pages/LectureArchive"));
const LectureVideo = React.lazy(
  () => import("./pages/LectureVideo/LectureVideo")
);
const OnlineLecture = React.lazy(
  () => import("./pages/OnlineLecture/OnlineLecture")
);
const Tests = React.lazy(() => import("./pages/Tests/Tests"));
const StartExam = React.lazy(() => import("./pages/Tests/StartExam"));
const Workout = React.lazy(() => import("./pages/Tests/Workout"));
const TicketsThemes = React.lazy(() => import("./pages/Tests/TicketsThemes"));
const Exam = React.lazy(() => import("./pages/Tests/Exam"));

moment.locale("ru");

const AppContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

const GlobalStyle = createGlobalStyle`
  .MuiMenu-paper {
    max-height: 400px !important;
  }
`;

const addMetaTagVersion = () => {
  const meta = document.createElement("meta");
  meta.name = "version_build";
  meta.content = `${process.env.VERSION_APP}`;
  document.getElementsByTagName("head")[0].appendChild(meta);
};

addMetaTagVersion();
initCanvasDrawSettings();

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userName = useSelector(getUserName);
  const userRole = useSelector(getUserRole);
  const isLoad = useSelector(getLoader);
  useCentrifugeConnected();

  const handleResizeWindow = useCallback(() => {
    dispatch(setClientWidth(window.innerWidth));
  }, [dispatch]);

  //resize window handler for adaptive design
  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);

    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [handleResizeWindow]);

  useEffect(() => {
    checkDeviceSupport((devices, permissions) =>
      dispatch(setUserDevices({ devices, permissions }))
    );
  }, [dispatch]);

  useEffect(() => {
    if (!getToken()) {
      dispatch(push("/login"));
      dispatch(setLoader({ value: false }));
    }

    dispatch(getUserInfoActionCreator(history.push));
    // eslint-disable-next-line
  }, [dispatch, userName]);

  return (
    <AppContainer>
      <GlobalStyle />
      <Switch>
        <Route exact path="/online-webinar">
          <RedirectForManager>
            <ListenerOnlineWebinar />
          </RedirectForManager>
        </Route>
        <Route
          exact
          path={LOGIN_PATH}
          component={() => {
            return <Login />;
          }}
        />
        <Route path="*">
          {isLoad && <Preloader />}
          {userRole !== "teacher" && <AppBar />}
          <ContentContainer>
            <Header />
            <Suspense fallback={<div>Загрузка...</div>}>
              <Switch>
                <Route exact path={["/cabinet", "/"]}>
                  <RedirectForManager to={WEBINARS_PATH}>
                    <Cabinet />
                  </RedirectForManager>
                </Route>
                <Route exact path={ONLINE_LECTURE_PATH}>
                  <RedirectForManager>
                    <OnlineLecture />
                  </RedirectForManager>
                </Route>
                <Route exact path={[WEBINARS_PATH]}>
                  <RedirectForUser>
                    <Webinars />
                  </RedirectForUser>
                </Route>
                <Route exact path={DOCUMENTS_PATH}>
                  <RedirectForManager>
                    <Documents />
                  </RedirectForManager>
                </Route>
                <Route exact path={START_EXAM}>
                  <RedirectForManager>
                    <Tests>
                      <StartExam />
                    </Tests>
                  </RedirectForManager>
                </Route>
                <Route exact path={WORKOUT}>
                  <RedirectForManager>
                    <Tests>
                      <Workout />
                    </Tests>
                  </RedirectForManager>
                </Route>
                <Route exact path={TICKETS_THEMES}>
                  <RedirectForManager>
                    <Tests>
                      <TicketsThemes />
                    </Tests>
                  </RedirectForManager>
                </Route>
                <Route exact path={EXAM}>
                  <RedirectForManager>
                    <Exam />
                  </RedirectForManager>
                </Route>
                <Route exact path={TESTS}>
                  <RedirectForManager>
                    <Redirect to={TICKETS_THEMES} />
                  </RedirectForManager>
                </Route>
                <Route exact path={SERVICES_AND_PAYMENTS_PATH}>
                  <RedirectForManager>
                    <Payments />
                  </RedirectForManager>
                </Route>
                <Route exact path={LECTURE_ARCHIVE_PATH}>
                  <RedirectForManager>
                    <LectureArchive />
                  </RedirectForManager>
                </Route>
                <Route exact path={LECTURE_VIDEO_PATH}>
                  <RedirectForManager>
                    <LectureVideo />
                  </RedirectForManager>
                </Route>
                <Route exact path={BASE_COURSES}>
                  <BaseCourses />
                </Route>
                <Route exact path={BASE_COURSES_VIEW}>
                  <BaseCourseView />
                </Route>
                <Route exact path={BASE_COURSES_LESSON}>
                  <BaseCourseVideo />
                </Route>
                <Route exact path="/payments">
                  <PaymentsPage />
                </Route>
                <Route path="*">
                  <InWork />
                </Route>
              </Switch>
            </Suspense>
          </ContentContainer>
          {/*<AppWidget />*/}
          <OnlineWebinarLectureWidget />
        </Route>
      </Switch>
      <ModalWindow />
      <Notifications />
    </AppContainer>
  );
};

export default App;
