import WebinarTabContainer from "../WebinarTabContainer";
import { SidebarWrapper, StyledWebinarGroupInfo } from "./styledComponents";
import { WebinarFeedbackPanel } from "../index";
import { useContext, useEffect } from "react";
import { PingOnlineStatusContext } from "../../../../providers/PingOnlineStatusProvider";
import { useSelector } from "react-redux";
import { getUserId } from "../../../../selectors/userInfo";
import { getWebinarId } from "../../redux";

const Webinar = () => {
  const { checkOnlineStatus } = useContext(PingOnlineStatusContext);
  const userId = useSelector(getUserId);
  const webinarId = useSelector(getWebinarId);

  useEffect(() => {
    if (userId && webinarId) {
      checkOnlineStatus();
    }
  }, [checkOnlineStatus, userId, webinarId]);

  useEffect(() => {
    return () => checkOnlineStatus();
  }, []);

  return (
    <>
      <WebinarTabContainer />
      <SidebarWrapper>
        <StyledWebinarGroupInfo />
        <WebinarFeedbackPanel />
      </SidebarWrapper>
    </>
  );
};

export default Webinar;
