import {
  setCabinetData,
  setCabinetLecturePromoLoad,
  setLecturePromoUsers,
  setLoadLecturePromoUsers,
} from "pages/Cabinet/redux/reducer";
import { TThunkDispatch } from "store";
import centrifuge from "./Centrifuge";
import { ILecture } from "./models/lecture";
import { subscribeToLectureChatChannel } from "./UserLectureChatAPI";
import { subscribeToTaskChannel } from "./InteractiveTaskAPI";
import { subscribeToWebinarChannel } from "./OnlineLectureAPI";
import {
  fetchWebinarVoiceMessages,
  fetchWebinarChatMessages,
  subscribeTeacherChannel,
} from "./TeacherWebinarChatAPI";
import { setWebinarId } from "../pages/Webinars/redux";
import axios from "axios";
import { API_URL, getHeaderWithAuthorization } from "./API";

type Res = {
  data: {
    webinar: ILecture;
  };
};

export const getCabinetPromoLectureActionCreator = () => {
  return (dispatch: TThunkDispatch) => {
    // dispatch(setCabinetLecturePromoLoad(true));
    centrifuge.rpc({ method: "webinar/get", data: {} }).then(
      function (res: Res) {
        dispatch(setCabinetData(res.data.webinar));
        dispatch(subscribeToWebinarChannel(`webinar${res.data.webinar.id}`));

        dispatch(subscribeToLectureChatChannel(`chat${res.data.webinar.id}`));
        dispatch(subscribeToTaskChannel(`task${res.data.webinar.id}`));
        dispatch(subscribeTeacherChannel(`teacher${res.data.webinar.id}`));
        dispatch(setWebinarId(res.data.webinar.id));
        dispatch(fetchWebinarChatMessages());
        dispatch(fetchWebinarVoiceMessages());
        dispatch(setCabinetLecturePromoLoad(false));
      },
      function (err) {
        dispatch(setCabinetData(null));
        dispatch(setCabinetLecturePromoLoad(false));
        console.log("rpc error 1", err);
      }
    );
  };
};

export const getCabinetPromoLectureWithoutSubscribeActionCreator = () => {
  return (dispatch: TThunkDispatch) => {
    centrifuge.rpc({ method: "webinar/get", data: {} }).then(
      function (res: Res) {
        dispatch(setCabinetData(res.data.webinar));
        dispatch(setWebinarId(res.data.webinar.id));
        dispatch(fetchWebinarChatMessages());
        dispatch(fetchWebinarVoiceMessages());
        dispatch(setCabinetLecturePromoLoad(false));
      },
      function (err) {
        dispatch(setCabinetData(null));
        dispatch(setCabinetLecturePromoLoad(false));
        console.log("rpc error 2", err);
      }
    );
  };
};

export const subscribeToChat = () => {
  return (dispatch: TThunkDispatch) => {
    centrifuge.rpc({ method: "webinar/get", data: {} }).then(
      function (res: Res) {
        dispatch(setCabinetData(res.data.webinar));
        dispatch(subscribeToWebinarChannel(`webinar${res.data.webinar.id}`));
        dispatch(subscribeToTaskChannel(`task${res.data.webinar.id}`));
        dispatch(subscribeTeacherChannel(`teacher${res.data.webinar.id}`));
        dispatch(setWebinarId(res.data.webinar.id));
        dispatch(fetchWebinarChatMessages());
        dispatch(fetchWebinarVoiceMessages());
        dispatch(setCabinetLecturePromoLoad(false));
      },
      function (err) {
        dispatch(setCabinetData(null));
        dispatch(setCabinetLecturePromoLoad(false));
        console.log("rpc error 3", err);
      }
    );
  };
};

export const updatePromoLecture = () => {
  return (dispatch: TThunkDispatch) => {
    centrifuge.rpc({ method: "webinar/get", data: {} }).then((res: Res) => {
      dispatch(setCabinetData(res.data.webinar));
    });
  };
};

export const getCabinetLecturePromoUsers =
  (id: number) => async (dispatch: TThunkDispatch) => {
    dispatch(setLoadLecturePromoUsers(true));
    try {
      const resp = await axios.post(
        `${API_URL}v2/webinar/users/${id}?size=999`,
        {},
        {
          headers: getHeaderWithAuthorization(),
        }
      );
      dispatch(setLecturePromoUsers(resp.data.items));
    } catch (err: any) {
      console.log({ err });
    }
  };

export const getBaseCoursesLessonByTopic = async (
  topic_id: string
): Promise<any> => {
  try {
    return await axios.post(
      `${API_URL}v2/base-course/lessons?size=999`,
      {
        topic_id,
      },
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    console.log({ err });
  }
};

export const getBaseCoursesTopics = async (): Promise<any> => {
  try {
    return await axios.post(
      `${API_URL}v2/base-course/topics?size=999`,
      {},
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    console.log({ err });
  }
};

export const getBaseCourseLessonById = async (id: string): Promise<any> => {
  try {
    return await axios.get(`${API_URL}v2/base-course/lesson-info?id=${id}`, {
      headers: getHeaderWithAuthorization(),
    });
  } catch (err: any) {
    console.log({ err });
  }
};

export const sendWatchVideoBaseCourse = async (id: string): Promise<any> => {
  try {
    return await axios.post(
      `${API_URL}v2/base-course/set-watched`,
      { lesson_id: id },
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    console.log({ err });
  }
};

export const getBillingOrdered = async (): Promise<any> => {
  try {
    return await axios.post(
      `${API_URL}v2/billing/service/ordered?size=999`,
      {},
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    console.log({ err });
  }
};

export const getBillingHistory = async (): Promise<any> => {
  try {
    return await axios.post(
      `${API_URL}v2/billing/order/history?size=999`,
      {},
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    console.log({ err });
  }
};

export const payBillingItem = async (
  id: number,
  amount: string
): Promise<any> => {
  try {
    return await axios.post(
      `${API_URL}v2/billing/order/link`,
      {
        service_user_id: id,
        amount: amount,
      },
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    console.log({ err });
  }
};

export const getBillingAllServices = async (): Promise<any> => {
  try {
    return await axios.post(
      `${API_URL}v2/billing/service`,
      {},
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    console.log({ err });
  }
};

export const addBillingServiceUser = async (ids: number[]): Promise<any> => {
  try {
    return await axios.post(
      `${API_URL}v2/billing/order/add`,
      { ids },
      {
        headers: getHeaderWithAuthorization(),
      }
    );
  } catch (err: any) {
    console.log({ err });
  }
};

export const getBaseCourseProgress = async (): Promise<any> => {
  try {
    return await axios.get(`${API_URL}v2/base-course/progress`, {
      headers: getHeaderWithAuthorization(),
    });
  } catch (err: any) {
    console.log({ err });
  }
};
