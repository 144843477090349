import styled from "styled-components";

export const Container = styled.div`
  width: 360px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgb(202 203 220 / 25%);
  padding: 40px 32px;
  box-sizing: border-box;
  margin-bottom: 30px;
`;

export const ImageContainer = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 32px;
`;

export const Text = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: 400;
  line-height: 27px;
`;
